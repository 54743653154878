import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
      <p>Last updated: August 09, 2024</p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to [Your Website Name]! These Terms and Conditions govern your
        use of our website located at [your-website-url.com] operated by [Your
        Company Name]. By accessing our website, you agree to be bound by these
        Terms and Conditions. If you do not agree to these terms, please do not
        use our website.
      </p>

      <h2>2. Intellectual Property Rights</h2>
      <p>
        Unless otherwise stated, [Your Company Name] and/or its licensors own
        the intellectual property rights published on this website and materials
        used on [Your Website Name]. All these intellectual property rights are
        reserved.
      </p>

      <h2>3. User Obligations</h2>
      <p>
        As a user of our website, you agree to not engage in any activities that
        may:
      </p>
      <ul>
        <li>Cause damage to the website or impair its performance.</li>
        <li>Violate any applicable laws or regulations.</li>
        <li>Infringe upon the rights of any third parties.</li>
        <li>Promote or distribute harmful or offensive content.</li>
      </ul>

      <h2>4. Limitation of Liability</h2>
      <p>
        [Your Company Name] will not be liable for any damages arising out of or
        in connection with your use of the website. This limitation of liability
        applies to all damages of any kind, including but not limited to direct,
        indirect, incidental, punitive, or consequential damages.
      </p>

      <h2>5. Links to Other Websites</h2>
      <p>
        Our website may contain links to third-party websites or services that
        are not owned or controlled by [Your Company Name]. We have no control
        over, and assume no responsibility for, the content, privacy policies,
        or practices of any third-party websites or services.
      </p>

      <h2>6. Termination</h2>
      <p>
        We may terminate or suspend your access to our website immediately,
        without prior notice or liability, for any reason whatsoever, including
        without limitation if you breach the Terms and Conditions.
      </p>

      <h2>7. Changes to These Terms</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms and Conditions at any time. If a revision is material, we will try
        to provide at least 30 days' notice prior to any new terms taking
        effect. What constitutes a material change will be determined at our
        sole discretion.
      </p>

      <h2>8. Governing Law</h2>
      <p>
        These Terms and Conditions are governed by and construed in accordance
        with the laws of [Your State/Country], and you irrevocably submit to the
        exclusive jurisdiction of the courts in that State or location.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions about these Terms and Conditions, please
        contact us at [your-contact-email@example.com].
      </p>

      {/* Google AdSense Hint */}
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <p>
          <strong>Note:</strong> This website uses Google AdSense to serve ads.
          Please review your Google AdSense terms of service and ensure your
          usage complies.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
