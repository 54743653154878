import React, { useState, useEffect, Fragment } from "react";
import { toast } from "react-toastify";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  collection,
  getDocs,
  orderBy,
  query,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import Spinner from "../components/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { FcAddDatabase } from "react-icons/fc";
import {
  FaCopy,
  FaTrash,
  FaTags,
  FaSearch,
  FaEdit,
  FaTimes,
  FaQuestionCircle,
  FaPaw,
} from "react-icons/fa"; // Import the copy and trash icons
import {
  FaArrowDownAZ,
  FaArrowDownZA,
  FaBurger,
  FaPlane,
  FaBook,
  FaLaptopCode,
  FaMasksTheater,
  FaFilterCircleXmark,
  FaCheck,
  FaHourglass,
  FaHashtag,
  FaPalette,
  FaChildren,
  FaTree,
  FaPerson,
  FaTableTennisPaddleBall,
  FaCircleChevronRight,
  FaCircleChevronLeft,
  FaShirt,
  FaHouse,
  FaClock,
  FaCalendarDays,
} from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { Menu, Transition } from "@headlessui/react";
import { TAGS } from "../tags";
import { STATUS } from "../status";
import { RecordCount } from "../components/RecordCount";

export default function AdminDictionary() {
  const [records, setRecords] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [filteredRecords, setFilteredRecords] = useState([]); // State for filtered records
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sortOption, setSortOption] = useState("german_asc");
  const [showAllTags, setShowAllTags] = useState(false);
  const [visibleTags, setVisibleTags] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialize useNavigate

  const openAssignTagsPage = (record) => {
    navigate("/assign-tags", { state: { record } });
  };

  const openRecordEditPage = (record) => {
    // Navigate to the edit page with only serializable data
    navigate("/edit-record", { state: { selectedRecord: record } });
  };

  // Function to fetch records from Firestore
  const fetchRecords = async () => {
    try {
      const recordRef = collection(db, "records");
      const q = query(recordRef, orderBy("germanWord", "asc"));
      const querySnap = await getDocs(q);
      const recordsData = [];
      querySnap.forEach((doc) => {
        const data = doc.data();
        if (data.germanWord && data.translation) {
          recordsData.push({
            id: doc.id,
            german: data.germanWord,
            translation: data.translation,
            phoneticSpelling: data.phoneticSpelling,
            status: data.status,
            tags: data.tags || [],
            article: data.article,
          });
        }
      });

      // Sort the records based on the selected option
      let sortedRecords = [...recordsData];
      if (sortOption === "german_asc") {
        sortedRecords = recordsData.sort((a, b) =>
          a.german.localeCompare(b.german, undefined, { sensitivity: "base" })
        );
      } else if (sortOption === "german_desc") {
        sortedRecords = recordsData.sort((a, b) =>
          b.german.localeCompare(a.german, undefined, { sensitivity: "base" })
        );
      } else if (sortOption === "malagasy_asc") {
        sortedRecords = recordsData.sort((a, b) =>
          a.translation.localeCompare(b.translation, undefined, {
            sensitivity: "base",
          })
        );
      } else if (sortOption === "malagasy_desc") {
        sortedRecords = recordsData.sort((a, b) =>
          b.translation.localeCompare(a.translation, undefined, {
            sensitivity: "base",
          })
        );
      } else if (sortOption === "numeric_asc") {
        sortedRecords = recordsData.sort(
          (a, b) => parseFloat(a.german) - parseFloat(b.german)
        );
      } else if (sortOption === "numeric_desc") {
        sortedRecords = recordsData.sort(
          (a, b) => parseFloat(b.german) - parseFloat(a.german)
        );
      }

      // Ensure numeric sorting for German and Malagasy fields
      const isNumeric = (value) => !isNaN(parseFloat(value)) && isFinite(value);

      sortedRecords = sortedRecords.sort((a, b) => {
        const numA = isNumeric(a.german) ? parseFloat(a.german) : a.german;
        const numB = isNumeric(b.german) ? parseFloat(b.german) : b.german;

        return numA - numB;
      });

      setRecords(recordsData);
      setLoading(false);
      // Initialize filteredRecords with the sorted records on initial load
      setFilteredRecords(sortedRecords);
    } catch (error) {
      toast.error(t("Toast.FetchListing.Error"));
    }
  };

  useEffect(() => {
    // Fetch records when the component mounts
    fetchRecords();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  useEffect(() => {
    // Initially, show the first three tags
    setVisibleTags(Object.values(TAGS).slice(0, 3));
  }, []);

  const toggleShowAllTags = () => {
    setShowAllTags(!showAllTags);
    if (!showAllTags) {
      // Show all tags
      setVisibleTags(Object.values(TAGS));
    } else {
      // Show only the first three tags
      setVisibleTags(Object.values(TAGS).slice(0, 3));
    }
  };

  // Function to copy the cell value to the clipboard
  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    toast.success(t("Toast.CopyCell.Success"));
  };

  // Function to delete a document by ID and refresh the table
  const deleteRecord = async (recordId) => {
    try {
      const isConfirmed = window.confirm(t("ConfirmDeleteMessage"));
      if (isConfirmed) {
        const recordRef = doc(db, "records", recordId); // Assuming "records" is the collection name
        await deleteDoc(recordRef);
        // Document successfully deleted
        toast.success(t("Toast.DeleteRecord.Success"));
        // Refresh the table by fetching records again
        fetchRecords();
      }
    } catch (error) {
      // An error occurred while deleting the document
      console.error("Error deleting document: ", error);
    }
  };

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);

    // Filter records based on search term
    const filtered = records.filter((record) => {
      const searchTermLower = e.target.value.toLowerCase();
      const germanMatch =
        record.german && record.german.toLowerCase().includes(searchTermLower);
      const translationMatch =
        record.translation &&
        record.translation.toLowerCase().includes(searchTermLower);
      return germanMatch || translationMatch;
    });

    setFilteredRecords(filtered);
  };

  const handleSortOptionChange = (option) => {
    setSortOption(option);
    setIsDropdownOpen(false);

    // Create a copy of records for sorting
    const sortedRecords = [...records];

    // Perform sorting based on the selected option
    if (option === "german_asc") {
      sortedRecords.sort((a, b) => a.german.localeCompare(b.german));
    } else if (option === "german_desc") {
      sortedRecords.sort((a, b) => b.german.localeCompare(a.german));
    } else if (option === "malagasy_asc") {
      sortedRecords.sort((a, b) => a.translation.localeCompare(b.translation));
    } else if (option === "malagasy_desc") {
      sortedRecords.sort((a, b) => b.translation.localeCompare(a.translation));
    }

    // Update the filteredRecords state with the sorted array
    setFilteredRecords(sortedRecords);
  };

  const filterRecordsByTag = (tag) => {
    // Check if the clicked tag is the same as the currently selected tag
    const isSameTag = selectedTag === tag;

    // Update the selectedTag state
    setSelectedTag(isSameTag ? null : tag);

    // Filter records based on the selected tag (case-insensitive)
    const filtered = records.filter((record) => {
      const match =
        record.tags &&
        record.tags.some((t) => {
          const lowerTag = t.toLowerCase();
          const lowerSelectedTag = tag.toLowerCase();
          return lowerTag === lowerSelectedTag;
        });

      const statusMatch =
        selectedStatus &&
        record.status &&
        record.status.toLowerCase() === selectedStatus.toLowerCase();

      if (selectedStatus) {
        return isSameTag ? statusMatch : match && statusMatch;
      } else {
        return isSameTag ? true : match;
      }
    });

    // Update the filteredRecords state
    setFilteredRecords(filtered);
  };

  const filterRecordsByStatus = (status) => {
    // Check if the clicked status is the same as the currently selected status
    const isSameStatus = selectedStatus === status;

    // Update the selectedStatus state
    setSelectedStatus(isSameStatus ? null : status);

    // Filter records based on both the selected tag and status (case-insensitive)
    const filtered = records.filter((record) => {
      const tagMatch =
        selectedTag &&
        record.tags &&
        record.tags.some((t) => {
          const lowerTag = t.toLowerCase();
          const lowerSelectedTag = selectedTag.toLowerCase();
          return lowerTag === lowerSelectedTag;
        });

      const statusMatch =
        record.status && record.status.toLowerCase() === status.toLowerCase();

      if (selectedTag) {
        // Both tag and status should match
        return isSameStatus ? tagMatch : tagMatch && statusMatch;
      } else {
        // Only status should match
        return isSameStatus ? true : statusMatch;
      }
    });

    // Update the filteredRecords state
    setFilteredRecords(filtered);
  };

  const resetFilters = () => {
    // Reset the selectedTag state to null to clear the filter
    setSelectedTag(null);
    setSelectedStatus(null);
    // Fetch records with the reset filter
    setFilteredRecords(records);
  };

  const getTagIcon = (tag) => {
    switch (tag) {
      case `${TAGS.FOOD}`:
        return <FaBurger className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.TRAVEL}`:
        return <FaPlane className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.EDUCATION}`:
        return <FaBook className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.TECHNOLOGY}`:
        return <FaLaptopCode className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.CULTURE}`:
        return <FaMasksTheater className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.NUMBER}`:
        return <FaHashtag className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.ANIMALS}`:
        return <FaPaw className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.COLORS}`:
        return <FaPalette className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.FAMILY}`:
        return <FaChildren className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.NATURE}`:
        return <FaTree className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.BODY}`:
        return <FaPerson className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.ACTIVITY}`:
        return (
          <FaTableTennisPaddleBall
            className="mr-2 h-5 w-5"
            aria-hidden="true"
          />
        );
      case `${TAGS.CLOTHING}`:
        return <FaShirt className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.HOUSE}`:
        return <FaHouse className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.TIME}`:
        return <FaClock className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.CALENDAR}`:
        return <FaCalendarDays className="mr-2 h-5 w-5" aria-hidden="true" />;
      default:
        return null; // You can customize this default case if needed
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case `${STATUS.ACCEPTED}`:
        return <FaCheck className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${STATUS.PENDING}`:
        return <FaHourglass className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${STATUS.REQUESTED}`:
        return <FaQuestionCircle className="mr-2 h-5 w-5" aria-hidden="true" />;
      default:
        return null; // You can customize this default case if needed
    }
  };

  const handleClearSearch = () => {
    // Reset the search term
    setSearchTerm("");
    // Reset the filtered records to all records
    setFilteredRecords(records);
  };

  return (
    <div className="max-w-6xl mx-auto px-3 flex flex-col items-center justify-center">
      <h1 className="text-3xl text-center mt-6 font-bold mb-6">
        Admin - {t("Dictionary")}
      </h1>

      <button
        type="submit"
        className="lg:w-[40%] bg-blue-600 text-white uppercase px-7 py-3 text-sm font-medium rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg active:bg-blue-800 mb-6"
      >
        <Link
          to="/admin-create-record"
          className="flex justify-center items-center"
        >
          <FcAddDatabase className="mr-2 text-3xl bg-white rounded-full p-1 border-2" />
          {t("Dictionary.CreateButton")}
        </Link>
      </button>
      <div className="mb-4 relative">
        <div className="flex items-center">
          <span className="absolute left-4 text-gray-500">
            <FaSearch
              className={`transition-colors ${
                searchTerm ? "text-blue-600" : ""
              }`}
            />
          </span>
          <input
            type="text"
            placeholder={t("Dictionary.SearchPlaceholder")}
            value={searchTerm}
            onChange={handleSearchInputChange}
            className="w-full px-10 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600"
          />
          {searchTerm && (
            <span
              onClick={handleClearSearch}
              className="absolute right-4 cursor-pointer text-red-500 hover:text-red-600 transition duration-150 ease-in-out"
            >
              <FaTimes />
            </span>
          )}
        </div>
      </div>
      <div className="mb-4 relative">
        <Menu as="div" className="relative inline-block text-left mr-4">
          <div>
            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:bg-blue-700">
              {t("Dictionary.SortBy")}{" "}
              <ChevronDownIcon
                className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => handleSortOptionChange("german_asc")}
                      className={`${
                        active ? "bg-blue-600 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {active ? (
                        <FaArrowDownAZ
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      ) : (
                        <FaArrowDownAZ
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                      {t("German")}
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => handleSortOptionChange("german_desc")}
                      className={`${
                        active ? "bg-blue-600 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {active ? (
                        <FaArrowDownZA
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      ) : (
                        <FaArrowDownZA
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                      {t("German")}
                    </button>
                  )}
                </Menu.Item>
              </div>
              <div className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => handleSortOptionChange("malagasy_asc")}
                      className={`${
                        active ? "bg-blue-600 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {active ? (
                        <FaArrowDownAZ
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      ) : (
                        <FaArrowDownAZ
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                      {t("Malagasy")}
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => handleSortOptionChange("malagasy_desc")}
                      className={`${
                        active ? "bg-blue-600 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {active ? (
                        <FaArrowDownZA
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      ) : (
                        <FaArrowDownZA
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                      {t("Malagasy")}
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        <Menu as="div" className="relative inline-block text-left mr-4">
          <div>
            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:bg-blue-700">
              {t("Options")}{" "}
              <ChevronDownIcon
                className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => resetFilters()}
                    className={`${
                      active ? "bg-red-600 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    {active ? (
                      <FaFilterCircleXmark
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    ) : (
                      <FaFilterCircleXmark
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                    {t("Reset")}
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <div className="mb-4">
        <div className="flex flex-wrap gap-2 justify-center">
          {visibleTags.map((tag) => (
            <button
              key={tag}
              onClick={() => filterRecordsByTag(tag)}
              className={`${
                selectedTag === tag
                  ? "bg-blue-700 text-white"
                  : "text-gray-900 border-2 hover:border-blue-600 hover:text-blue-600"
              } group flex items-center rounded-md px-2 py-2 text-sm sm:text-sm`}
            >
              {getTagIcon(tag)}
              {t(`Tags.${tag}`)}
            </button>
          ))}
          <button
            onClick={toggleShowAllTags}
            className={`${
              showAllTags
                ? "bg-orange-600 text-white hover:bg-orange-700"
                : "bg-blue-600 text-white hover:bg-blue-700"
            } group flex items-center rounded-md px-2 py-2 text-sm sm:text-sm`}
          >
            {showAllTags ? (
              <>
                <FaCircleChevronLeft className="mr-2" />
                {t("ShowLessTags")}
              </>
            ) : (
              <>
                <FaCircleChevronRight className="mr-2" />
                {t("ShowAllTags")}
              </>
            )}
          </button>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : filteredRecords && filteredRecords.length > 0 ? (
        <div className="w-full overflow-x-auto mb-20">
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                <th className="left-0 z-10 border border-gray-300 px-4 py-2">
                  {t("German")} (<RecordCount status="Accepted" />)
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  {t("Malagasy")}
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  {t("Tags")}
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  {t("Status")}
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  {t("Actions")}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredRecords.map((record) => (
                <tr key={record.id}>
                  <td className="border border-gray-300 px-4 py-2">
                    <div className="flex justify-between items-center">
                      {record.article
                        ? `${record.german} (${record.article})`
                        : record.german}
                      <span
                        onClick={() => copyToClipboard(record.german)}
                        className="cursor-pointer text-gray-500 hover:text-gray-600 transition duration-150 ease-in-out"
                      >
                        <FaCopy />
                      </span>
                    </div>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <div className="flex justify-between items-center">
                      <span className="flex items-center">
                        {record.phoneticSpelling
                          ? `${record.translation} [${record.phoneticSpelling}]`
                          : record.translation}
                      </span>
                      <span
                        onClick={() => copyToClipboard(record.translation)}
                        className="cursor-pointer text-gray-500 hover:text-gray-600 transition duration-150 ease-in-out"
                      >
                        <FaCopy />
                      </span>
                    </div>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <div className="flex justify-between items-center">
                      {record.tags.map((tag, index) => (
                        <span
                          key={index}
                          className="inline-flex items-center gap-1 rounded-full bg-blue-300 px-2 py-1 text-xs font-semibold text-blue-600"
                        >
                          {t(`Tags.${tag}`)}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <div className="flex justify-between items-center">
                      {record.status === "Accepted" ? (
                        <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
                          <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                          {t("Status.Accepted")}
                        </span>
                      ) : record.status === "Pending" ? (
                        <span className="inline-flex items-center gap-1 rounded-full bg-orange-50 px-2 py-1 text-xs font-semibold text-orange-600">
                          <span className="h-1.5 w-1.5 rounded-full bg-orange-600"></span>
                          {t("Status.Pending")}
                        </span>
                      ) : record.status === "Requested" ? (
                        <span className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600">
                          <span className="h-1.5 w-1.5 rounded-full bg-blue-600"></span>
                          {t("Status.Requested")}
                        </span>
                      ) : (
                        record.status
                      )}
                    </div>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <div className="flex justify-left items-center">
                      <span
                        onClick={() => openRecordEditPage(record)}
                        className="cursor-pointer text-orange-500 hover:text-orange-600 transition duration-150 ease-in-out mr-2"
                      >
                        <FaEdit />
                      </span>
                      <span
                        onClick={() => openAssignTagsPage(record)}
                        className="cursor-pointer text-blue-500 hover:text-blue-600 transition duration-150 ease-in-out mr-2"
                      >
                        <FaTags />
                      </span>
                      <span
                        onClick={() => deleteRecord(record.id)}
                        className="cursor-pointer text-red-500 hover:text-red-600 transition duration-150 ease-in-out"
                      >
                        <FaTrash />
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>{t("NoRecordsFound")}</p>
      )}
    </div>
  );
}
