import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { db } from "../firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FaUserPlus } from "react-icons/fa";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

export default function NewUserPage({ setUsers, updateUsersList }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    status: "active",
  });
  const { name, email, password } = formData;
  const { t } = useTranslation();

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function onSubmit(e) {
    e.preventDefault();

    try {
      const auth = getAuth();
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await updateProfile(auth.currentUser, {
        displayName: name,
      });
      const user = userCredentials.user;
      const formDataCopy = { ...formData };
      delete formDataCopy.password;
      formDataCopy.timestamp = serverTimestamp();
      await setDoc(doc(db, "users", user.uid), formDataCopy);
      updateUsersList();
      toast.success(t("Toast.SignUp.Success"));
      navigate("/users"); // Navigate to the users list page after successful creation
    } catch (error) {
      toast.error(t("Toast.SignUp.Error"));
    }
  }

  const handleCancel = () => {
    // Navigate back to the previous page
    navigate("/users");
  };

  return (
    <div className="max-w-lg mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <div className="flex items-center mb-6">
        <FaUserPlus className="h-6 w-6 text-blue-600 mr-2" />
        <h1 className="text-2xl font-semibold text-gray-900">{t("AddUser")}</h1>
      </div>
      <p className="text-sm text-gray-500 mb-4">{t("NewUserModal.Subtitle")}</p>
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            {t("Name")}
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="mt-1 p-2 w-full border rounded-md"
            value={name}
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            {t("Email")}
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="mt-1 p-2 w-full border rounded-md"
            value={email}
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            {t("Password")}
          </label>
          <div className="relative mt-1">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              className="p-2 w-full border rounded-md"
              value={password}
              onChange={onChange}
            />
            <span
              className="absolute right-0 top-0 h-full w-8 flex items-center justify-center cursor-pointer"
              onClick={() => setShowPassword((prevState) => !prevState)}
            >
              {showPassword ? (
                <AiFillEyeInvisible className="h-5 w-5 text-gray-500" />
              ) : (
                <AiFillEye className="h-5 w-5 text-gray-500" />
              )}
            </span>
          </div>
        </div>
        {/* Add status input field here if needed */}
        <div className="flex justify-end mt-6">
          <button
            type="button"
            className="ml-3 px-4 py-2 bg-gray-300 text-gray-700 font-medium rounded hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
            onClick={handleCancel}
          >
            {t("NewUserModal.Cancel")}
          </button>
          <button
            type="submit"
            className="ml-3 px-4 py-2 bg-blue-600 text-white font-medium rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            {t("NewUserModal.Save")}
          </button>
        </div>
      </form>
    </div>
  );
}
