import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  orderBy,
  query,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { FaTrash, FaPlus } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import UserDeactivateModal from "../components/UserDeactivateModal";
import UserActivateModal from "../components/UserActivateModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function UserList() {
  const [users, setUsers] = useState([]);
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const navigate = useNavigate();

  const openNewUserPage = () => {
    navigate("/new-user");
  };

  const fetchAndUpdateUsers = async () => {
    try {
      const recordRef = collection(db, "users");
      const q = query(recordRef, orderBy("name"));
      const usersSnapshot = await getDocs(q);
      const usersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    // Fetch users from Firebase database
    fetchAndUpdateUsers();
  }, []);

  const getInitials = (name) => {
    const names = name.split(" ");
    return names
      .map((word) => word.charAt(0))
      .join("")
      .toUpperCase();
  };

  const handleDelete = async (userId) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );

    if (shouldDelete) {
      try {
        // Delete user from Firebase Firestore
        await deleteDoc(doc(db, "users", userId));

        // Update the state to reflect the deletion
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
        toast.success(t("Toast.UsersList.Delete.Success"));
      } catch (error) {
        toast.error(t("Toast.UsersList.Delete.Error"));
      }
    }
  };

  const handleStatusClick = (userId) => {
    // Add logic to handle the status click here
    setSelectedUserId(userId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    // Close the modal and clear the selected user
    setModalOpen(false);
    setSelectedUserId(null);
  };

  return (
    <section className="max-w-6xl mx-auto">
      <h1 className="text-3xl text-center mt-6 font-bold">{t("Users")}</h1>
      <div className="flex flex-col items-center justify-center mb-6 mt-6">
        <button
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none"
          onClick={() => openNewUserPage()}
        >
          <div className="flex items-center gap-2">
            <FaPlus />
            {t("AddUser")}
          </div>
        </button>
      </div>
      <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md m-6">
        <div className="overflow-x-auto">
          <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
            <thead className="bg-gray-50">
              <tr>
                <th className="py-4 px-6">{t("Name")}</th>
                <th className="py-4 px-6">{t("Status")}</th>
                <th className="py-4 px-6">{t("CreatedAt")}</th>
                <th className="py-4 px-6">{t("LastLogin")}</th>
                <th className="py-4 px-6">{t("Actions")}</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 border-t border-gray-100">
              {users.map((user) => (
                <tr key={user.id} className="hover:bg-gray-50">
                  <td className="py-4 px-6">
                    <div className="flex gap-3 items-center">
                      <div className="relative h-10 w-10">
                        <div className="h-full w-full rounded-full bg-blue-500 flex items-center justify-center text-white font-bold">
                          {getInitials(user.name)}
                        </div>
                        {user.status === "active" ? (
                          <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span>
                        ) : (
                          <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-red-400 ring ring-white"></span>
                        )}
                      </div>
                      <div className="text-sm">
                        <div className="font-medium text-gray-700">
                          {user.name}
                        </div>
                        <div className="text-gray-400">{user.email}</div>
                      </div>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <div
                      className="flex justify-between items-center cursor-pointer"
                      onClick={() => handleStatusClick(user.id)}
                    >
                      {user.status === "active" ? (
                        <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
                          <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                          {t("Active")}
                        </span>
                      ) : user.status === "inactive" ? (
                        <span className="inline-flex items-center gap-1 rounded-full bg-red-50 px-2 py-1 text-xs font-semibold text-red-600">
                          <span className="h-1.5 w-1.5 rounded-full bg-red-600"></span>
                          {t("Inactive")}
                        </span>
                      ) : (
                        user.status
                      )}
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    {user.timestamp && user.timestamp.toDate().toLocaleString()}
                  </td>
                  <td className="py-4 px-6">
                    {user.lastLogin && user.lastLogin.toDate().toLocaleString()}
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex justify-end gap-4">
                      <FaTrash
                        onClick={() => handleDelete(user.id)}
                        className="text-red-600 hover:text-red-700 cursor-pointer"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {selectedUserId && (
        <>
          {users.find((user) => user.id === selectedUserId)?.status ===
          "active" ? (
            <UserDeactivateModal
              open={modalOpen}
              setOpen={setModalOpen}
              selectedUserId={selectedUserId}
              onClose={handleCloseModal}
              setUsers={setUsers}
            />
          ) : (
            <UserActivateModal
              open={modalOpen}
              setOpen={setModalOpen}
              selectedUserId={selectedUserId}
              onClose={handleCloseModal}
              setUsers={setUsers}
            />
          )}
        </>
      )}
    </section>
  );
}
