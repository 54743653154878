import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import chevron from "../assets/chevron.svg";
import { Link } from "react-router-dom";

export default function FAQ() {
  const { t } = useTranslation();

  const AccordionItem = ({ header, children, ...rest }) => (
    <Item
      {...rest}
      header={({ state: { isEnter } }) => (
        <>
          {header}
          <img
            className={`ml-auto transition-transform duration-200 ease-out ${
              isEnter && "rotate-180"
            }`}
            src={chevron}
            alt="Chevron"
          />
        </>
      )}
      className="border-b"
      buttonProps={{
        className: ({ isEnter }) =>
          `flex w-full p-4 text-left hover:bg-slate-300 ${
            isEnter && "bg-slate-300"
          }`,
      }}
      contentProps={{
        className: "transition-height duration-200 ease-out",
      }}
      panelProps={{ className: "p-4" }}
    >
      {children}
    </Item>
  );

  return (
    <>
      <section className="max-w-6xl mx-auto flex justify-center items-center flex-col">
        <h1 className="text-3xl text-center mt-6 font-bold">
          {t("FAQ.Title")}
        </h1>

        {/* General Questions */}
        <div className="w-full md:w-[50%] mt-6 px-3">
          <h2 className="text-xl font-bold mb-4">
            {t("FAQ.GeneralQuestions")}
          </h2>

          <Accordion>
            <AccordionItem
              header={t("FAQ.GeneralQuestions.WhatIsMadagassisch.de.Question")}
            >
              <Link to="/" className="text-blue-600 hover:text-blue-700">
                {" "}
                Madagassisch.de{" "}
              </Link>
              {t("FAQ.GeneralQuestions.WhatIsMadagassisch.de.Answer")}
            </AccordionItem>

            <AccordionItem
              header={t("FAQ.GeneralQuestions.HowToContact.Question")}
            >
              {t("FAQ.GeneralQuestions.HowToConact.Answer")}{" "}
              <Link to="/contact" className="text-blue-600 hover:text-blue-700">
                {" "}
                {t("Contact")}
              </Link>
            </AccordionItem>

            {/* <AccordionItem
              header={t("FAQ.GeneralQuestions.AdminAccess.Question")}
            >
              {t("FAQ.GeneralQuestions.AdminAccess.Answer")}
            </AccordionItem> */}

            <AccordionItem
              header={t("FAQ.GeneralQuestions.AddToHomescreenIPhone.Question")}
            >
              {t("FAQ.GeneralQuestions.AddToHomescreenIPhone.Answer")}
            </AccordionItem>

            <AccordionItem
              header={t("FAQ.GeneralQuestions.AddToHomescreenAndroid.Question")}
            >
              {t("FAQ.GeneralQuestions.AddToHomescreenAndroid.Answer")}
            </AccordionItem>
          </Accordion>
        </div>

        {/* Profile-related Questions */}
        <div className="w-full md:w-[50%] mt-6 px-3">
          <h2 className="text-xl font-bold mb-4">
            {t("FAQ.ProfileQuestions")}
          </h2>

          <Accordion>
            <AccordionItem
              header={t("FAQ.ProfileQuestions.WhereIsProfil.Question")}
            >
              {t("FAQ.ProfileQuestions.WhereIsProfil.Answer")}
            </AccordionItem>
          </Accordion>
        </div>
      </section>
    </>
  );
}
