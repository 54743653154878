import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { FaEdit } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

export default function RecordEditPage() {
  const [editedGermanWord, setEditedGermanWord] = useState("");
  const [editedTranslation, setEditedTranslation] = useState("");
  const [editedArticle, setEditedArticle] = useState("empty");
  const [editedPhoneticSpelling, setEditedPhoneticSpelling] = useState("");
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedRecord, setRecords } = location.state || {}; // Destructure state

  useEffect(() => {
    const fetchRecord = async () => {
      if (selectedRecord && selectedRecord.id) {
        try {
          const recordRef = doc(db, "records", selectedRecord.id);
          const recordDoc = await getDoc(recordRef);

          if (recordDoc.exists()) {
            const recordData = recordDoc.data();

            setEditedGermanWord(recordData.germanWord);
            setEditedTranslation(recordData.translation);
            setEditedArticle(recordData.article || "empty");
            setEditedPhoneticSpelling(recordData.phoneticSpelling || "");
          } else {
            console.log("Record does not exist.");
          }
        } catch (error) {
          console.error("Error fetching record data:", error);
        }
      }
    };

    fetchRecord();
  }, [selectedRecord]);

  const handleBack = () => {
    navigate(-1); // Navigate back
  };

  const handleEditSubmit = async () => {
    try {
      const updatedArticle = editedArticle === "empty" ? "" : editedArticle;

      const updateData = {
        germanWord: editedGermanWord && editedGermanWord.trim(),
        translation: editedTranslation && editedTranslation.trim(),
        phoneticSpelling:
          editedPhoneticSpelling && editedPhoneticSpelling.trim(),
      };

      if (updatedArticle !== "empty") {
        updateData.article = updatedArticle;
      }

      await updateDoc(doc(db, "records", selectedRecord.id), updateData);

      if (typeof setRecords === "function") {
        setRecords((prevRecords) =>
          prevRecords.map((record) =>
            record.id === selectedRecord.id
              ? {
                  ...record,
                  germanWord: editedGermanWord,
                  translation: editedTranslation,
                  article: updatedArticle,
                  phoneticSpelling: editedPhoneticSpelling,
                }
              : record
          )
        );
      }

      // Clear the fields and navigate back after successful update
      setEditedGermanWord("");
      setEditedTranslation("");
      setEditedArticle("empty");
      setEditedPhoneticSpelling("");

      toast.success(t("Toast.RecordEditModal.Success"));
      handleBack();
    } catch (error) {
      toast.error(t("Toast.RecordEditModal.Error"));
      console.error("Error updating translation record:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
        <div className="flex items-start">
          <div className="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
            <FaEdit className="h-6 w-6 text-blue-600" />
          </div>
          <div className="ml-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t("RecordEditModal.EditTranslation")}
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              {t("RecordEditModal.Subtitle")}
            </p>
          </div>
        </div>
        <div className="mt-5">
          <label
            htmlFor="article"
            className="block text-sm font-medium text-gray-700"
          >
            {t("Article")}
          </label>
          <select
            id="article"
            value={editedArticle}
            onChange={(e) => setEditedArticle(e.target.value)}
            className="mt-1 p-2 w-full border rounded-md"
          >
            <option value="der">der</option>
            <option value="die">die</option>
            <option value="das">das</option>
            <option value="empty">{t("NoArticle")}</option>
          </select>
          <label
            htmlFor="germanWord"
            className="mt-4 block text-sm font-medium text-gray-700"
          >
            {t("RecordEditModal.GermanWord")}
          </label>
          <input
            type="text"
            name="germanWord"
            id="germanWord"
            className="mt-1 p-2 w-full border rounded-md"
            value={editedGermanWord}
            onChange={(e) => setEditedGermanWord(e.target.value)}
          />
          <label
            htmlFor="translation"
            className="mt-4 block text-sm font-medium text-gray-700"
          >
            {t("RecordEditModal.Translation")}
          </label>
          <input
            type="text"
            name="translation"
            id="translation"
            className="mt-1 p-2 w-full border rounded-md"
            value={editedTranslation}
            onChange={(e) => setEditedTranslation(e.target.value)}
          />
          <label
            htmlFor="phoneticSpelling"
            className="mt-4 block text-sm font-medium text-gray-700"
          >
            {t("PhoneticSpelling")}
          </label>
          <input
            type="text"
            name="phoneticSpelling"
            id="phoneticSpelling"
            className="mt-1 p-2 w-full border rounded-md"
            value={editedPhoneticSpelling}
            onChange={(e) => setEditedPhoneticSpelling(e.target.value)}
          />
        </div>
        <div className="mt-5 flex justify-end">
          <button
            type="button"
            className="mr-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={handleBack}
          >
            {t("RecordEditModal.Cancel")}
          </button>
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={handleEditSubmit}
          >
            {t("RecordEditModal.Save")}
          </button>
        </div>
      </div>
    </div>
  );
}
