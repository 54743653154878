import React, { useState } from "react";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  query,
  where,
  serverTimestamp,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Switch from "react-switch";
import { TAGS } from "../tags";
import { FaInfoCircle } from "react-icons/fa";

export default function CreateRecord() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState("empty");
  const [phoneticSpelling, setPhoneticSpelling] = useState("");
  const [formData, setFormData] = useState({
    germanWord: "",
    translation: "",
    phoneticSpelling: "",
    status: "Pending",
    tags: [], // Initialize tags as an empty array
  });

  const { germanWord, translation, tags } = formData;
  const { t } = useTranslation();
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);
  const [existingRecord, setExistingRecord] = useState(null);

  function onChange(e) {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  function onChangeArticle(e) {
    setArticle(e.target.value);
  }

  const handleDuplicateDialogOpen = (record) => {
    setExistingRecord(record);
    setIsDuplicateDialogOpen(true);
  };

  const handleDuplicateDialogClose = () => {
    setIsDuplicateDialogOpen(false);
    setExistingRecord(null);
  };

  function onChangePhoneticSpelling(e) {
    setPhoneticSpelling(e.target.value);
  }

  // Function to handle tag selection
  const handleTagChange = (tag) => {
    if (tags.includes(tag)) {
      // Deselect the tag
      setFormData((prevState) => ({
        ...prevState,
        tags: tags.filter((selectedTag) => selectedTag !== tag),
      }));
    } else {
      // Select the tag
      setFormData((prevState) => ({
        ...prevState,
        tags: [...tags, tag],
      }));
    }
  };

  const getEnglishTags = (translatedTags) => {
    const tagMappings = {
      [t(`Tags.${TAGS.FOOD}`)]: TAGS.FOOD,
      [t(`Tags.${TAGS.TRAVEL}`)]: TAGS.TRAVEL,
      [t(`Tags.${TAGS.EDUCATION}`)]: TAGS.EDUCATION,
      [t(`Tags.${TAGS.TECHNOLOGY}`)]: TAGS.TECHNOLOGY,
      [t(`Tags.${TAGS.CULTURE}`)]: TAGS.CULTURE,
      [t(`Tags.${TAGS.NUMBER}`)]: TAGS.NUMBER,
      [t(`Tags.${TAGS.ANIMALS}`)]: TAGS.ANIMALS,
      [t(`Tags.${TAGS.COLORS}`)]: TAGS.COLORS,
      [t(`Tags.${TAGS.FAMILY}`)]: TAGS.FAMILY,
      [t(`Tags.${TAGS.NATURE}`)]: TAGS.NATURE,
      [t(`Tags.${TAGS.BODY}`)]: TAGS.BODY,
      [t(`Tags.${TAGS.ACTIVITY}`)]: TAGS.ACTIVITY,
      [t(`Tags.${TAGS.CLOTHING}`)]: TAGS.CLOTHING,
      [t(`Tags.${TAGS.HOUSE}`)]: TAGS.HOUSE,
      [t(`Tags.${TAGS.TIME}`)]: TAGS.TIME,
      [t(`Tags.${TAGS.CALENDAR}`)]: TAGS.CALENDAR,
      // Add more tag mappings if needed
    };

    return translatedTags.map((tag) => tagMappings[tag]);
  };

  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const duplicateField = await checkForDuplicates("germanWord", germanWord);
    if (duplicateField) {
      handleDuplicate(duplicateField);
      return;
    }

    const duplicateTranslation = await checkForDuplicates(
      "translation",
      translation
    );
    if (duplicateTranslation) {
      handleDuplicate(duplicateTranslation);
      return;
    }

    await createRecord();
  }

  async function onSubmitDuplicate(e) {
    e.preventDefault();
    setLoading(true);
    await createRecord();
  }

  async function checkForDuplicates(field, value) {
    const recordsRef = collection(db, "records");
    const querySnapshot = await getDocs(
      query(recordsRef, where(field, "==", value))
    );

    return querySnapshot.empty ? null : { field, value };
  }

  function handleDuplicate(duplicate) {
    handleDuplicateDialogOpen({
      field: duplicate.field,
      value: duplicate.value,
    });
    setLoading(false);
  }

  async function createRecord() {
    const submittedBy = auth.currentUser ? auth.currentUser.uid : "anonymous";

    const formDataCopy = {
      ...formData,
      dateSubmitted: serverTimestamp(),
      submittedBy: submittedBy,
      dateReviewed: serverTimestamp(),
      userRef: submittedBy,
      tags: getEnglishTags(tags),
      article: article === "empty" ? "" : article,
      phoneticSpelling: phoneticSpelling === undefined ? "" : phoneticSpelling,
    };

    try {
      await addDoc(collection(db, "records"), formDataCopy);
      setLoading(false);
      toast.success(t("Toast.CreateRecord.Success"));
      if (checked) {
        setFormData({
          germanWord: "",
          translation: "",
          phoneticSpelling: "",
          status: "Accepted",
          tags: [],
        });
        setArticle("empty");
      } else {
        navigate("/dictionary");
      }
    } catch (error) {
      setLoading(false);
      toast.error(t("Toast.CreateRecord.Error"));
      console.error("Error adding document: ", error);
    }
  }

  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  if (loading) {
    return <Spinner />;
  }

  // Define your available tags as an array
  const availableTags = [
    t(`Tags.${TAGS.FOOD}`),
    t(`Tags.${TAGS.TRAVEL}`),
    t(`Tags.${TAGS.EDUCATION}`),
    t(`Tags.${TAGS.TECHNOLOGY}`),
    t(`Tags.${TAGS.CULTURE}`),
    t(`Tags.${TAGS.NUMBER}`),
    t(`Tags.${TAGS.ANIMALS}`),
    t(`Tags.${TAGS.COLORS}`),
    t(`Tags.${TAGS.FAMILY}`),
    t(`Tags.${TAGS.NATURE}`),
    t(`Tags.${TAGS.BODY}`),
    t(`Tags.${TAGS.ACTIVITY}`),
    t(`Tags.${TAGS.CLOTHING}`),
    t(`Tags.${TAGS.HOUSE}`),
    t(`Tags.${TAGS.TIME}`),
    t(`Tags.${TAGS.CALENDAR}`),
  ];

  return (
    <main className="max-w-md px-2 mx-auto">
      <h1 className="text-3xl text-center mt-6 font-bold">
        {t("CreateRecord.Title")}
      </h1>
      <div className="flex justify-center mb-5 mt-5">
        <Switch
          onChange={handleChange}
          checked={checked}
          offColor="#ff0000"
          className="react-switch mr-2"
        />
        <p>
          {t("CreatorMode")}{" "}
          <span>{checked ? t("Active") : t("Inactive")}</span>.
        </p>
      </div>
      <form onSubmit={onSubmit}>
        <p className="text-lg mt-6 font-semibold">{t("Article")}</p>
        <select
          id="article"
          value={article}
          onChange={onChangeArticle}
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded mb-6"
        >
          <option value="der">der</option>
          <option value="die">die</option>
          <option value="das">das</option>
          <option value="empty">{t("NoArticle")}</option>
        </select>
        <p className="text-lg font-semibold">{t("German")}</p>
        <input
          type="text"
          id="germanWord"
          value={germanWord}
          onChange={onChange}
          placeholder={t("CreateRecord.Placeholder.German")}
          required
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
        />
        <p className="text-lg mt-6 font-semibold">{t("Malagasy")}</p>
        <input
          type="text"
          id="translation"
          value={translation}
          onChange={onChange}
          placeholder={t("CreateRecord.Placeholder.Malagasy")}
          required
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
        />
        <p className="text-lg font-semibold">{t("PhoneticSpelling")}</p>
        <input
          type="text"
          id="phoneticSpelling"
          value={phoneticSpelling}
          onChange={onChangePhoneticSpelling}
          placeholder={t("CreateRecord.Placeholder.PhoneticSpelling")}
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
        />
        <div className="mb-6 text-center text-gray-600">
          <FaInfoCircle className="inline mr-2 text-xl text-blue-600" />
          {t("CreateRecord.SpaceHint")}
        </div>
        <p className="text-lg mt-6 font-semibold">{t("Tags")}</p>
        <div className="mb-6">
          <div className="grid grid-cols-2">
            <div>
              {availableTags
                .slice(0, Math.ceil(availableTags.length / 2))
                .map((tag) => (
                  <div key={tag} className="mb-2">
                    <label className="block items-center">
                      <input
                        type="checkbox"
                        checked={tags.includes(tag)}
                        onChange={() => handleTagChange(tag)}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2">{tag}</span>
                    </label>
                  </div>
                ))}
            </div>
            <div>
              {availableTags
                .slice(Math.ceil(availableTags.length / 2))
                .map((tag) => (
                  <div key={tag} className="mb-2">
                    <label className="block items-center">
                      <input
                        type="checkbox"
                        checked={tags.includes(tag)}
                        onChange={() => handleTagChange(tag)}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2">{tag}</span>
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="mb-6 text-center text-gray-600">
          <FaInfoCircle className="inline mr-2 text-xl text-blue-600" />
          {t("CreateRecord.CheckHint")}
        </div>
        <button
          type="submit"
          className="mb-6 w-full px-7 py-3 bg-blue-600 text-white font-medium text-sm uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
        >
          {t("CreateRecord.Button")}
        </button>
      </form>
      {/* Duplicate Record Dialog */}
      {isDuplicateDialogOpen && existingRecord && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md max-w-md w-full">
            <div className="flex items-center">
              <FaInfoCircle className="mr-2 text-2xl text-yellow-500" />
              <h3 className="font-bold text-lg">
                {t("DuplicateValueWarningModal.Title")}
              </h3>
            </div>
            <p className="mt-2">{t("DuplicateValueWarningModal.Subtitle")}</p>
            <p className="mt-2">
              {t("DuplicateValueWarningModal.ConfirmationPrompt", {
                field:
                  existingRecord.field === "germanWord"
                    ? t("German")
                    : t("Malagasy"),
                value: existingRecord.value,
              })}
            </p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={handleDuplicateDialogClose}
                className="px-4 py-2 bg-gray-300 rounded-md text-gray-700 mr-2 hover:bg-gray-400"
              >
                {t("DuplicateValueWarningModal.Cancel")}
              </button>
              <button
                onClick={onSubmitDuplicate}
                className="px-4 py-2 bg-blue-600 rounded-md text-white hover:bg-blue-700"
              >
                {t("DuplicateValueWarningModal.CreateDuplicate")}
              </button>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
