import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import {
  AiOutlineDotChart,
  AiOutlineFileSearch,
  AiOutlineUserSwitch,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { RecordCount } from "../components/RecordCount";

export default function Dashboard() {
  const [userCount, setUserCount] = useState(0);
  const { t } = useTranslation();

  const getUserCount = async () => {
    try {
      const usersCollection = collection(db, "users"); // Replace "records" with your actual collection name
      const querySnapshot = await getDocs(usersCollection);
      return querySnapshot.size; // This gives you the count of documents in the collection
    } catch (error) {
      console.error("Error fetching user count:", error);
      return 0; // Handle the error gracefully
    }
  };

  useEffect(() => {
    // Fetch the record count and update the state
    getUserCount().then((count) => {
      setUserCount(count);
    });
  }, []);

  return (
    <div className="max-w-6xl mx-auto px-3 flex flex-col items-center justify-center">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h1 className="text-3xl text-center mt-6 font-bold mb-6">
            Admin Dashboard
          </h1>
        </div>

        <div className="mt-10">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <Link to="/Admin-Dictionary" className="hover:no-underline">
              <div className="bg-gray-200 flex items-center p-6 border rounded-lg shadow-md hover:shadow-lg transition duration-300">
                <div className="flex-shrink-0 mr-4">
                  <AiOutlineDotChart className="text-4xl" />
                </div>
                <div className="flex-1 flex flex-col justify-center">
                  <h2 className="text-center text-xl font-semibold text-gray-900 mb-2">
                    {t("Dashboard.TotalRecords")}
                  </h2>
                  <p className="text-center mt-4 text-4xl font-bold text-blue-600">
                    <RecordCount status="Accepted" />
                  </p>
                </div>
              </div>
            </Link>

            <Link to="/Admin-Dictionary-Review" className="hover:no-underline">
              <div className="bg-gray-200 flex items-center p-6 border rounded-lg shadow-md hover:shadow-lg transition duration-300">
                <div className="flex-shrink-0 mr-4">
                  <AiOutlineFileSearch className="text-4xl" />
                </div>
                <div className="flex-1 flex flex-col justify-center">
                  <h2 className="text-center text-xl font-semibold text-gray-900 mb-2">
                    {t("Dashboard.RecordsInReview")}
                  </h2>
                  <p className="text-center mt-4 text-4xl font-bold text-blue-600">
                    <RecordCount status="Pending" />
                  </p>
                </div>
              </div>
            </Link>

            <Link to="/Admin-Dictionary-Review" className="hover:no-underline">
              <div className="bg-gray-200 flex items-center p-6 border rounded-lg shadow-md hover:shadow-lg transition duration-300">
                <div className="flex-shrink-0 mr-4">
                  <AiOutlineQuestionCircle className="text-4xl" />
                </div>
                <div className="flex-1 flex flex-col justify-center">
                  <h2 className="text-center text-xl font-semibold text-gray-900 mb-2">
                    {t("Dashboard.RequestedRecords")}
                  </h2>
                  <p className="text-center mt-4 text-4xl font-bold text-blue-600">
                    <RecordCount status="Requested" />
                  </p>
                </div>
              </div>
            </Link>

            <Link to="/users" className="hover:no-underline">
              <div className="bg-gray-200 flex items-center p-6 border rounded-lg shadow-md hover:shadow-lg transition duration-300">
                <div className="flex-shrink-0 mr-4">
                  <AiOutlineUserSwitch className="text-4xl" />
                </div>
                <div className="flex-1 flex flex-col justify-center">
                  <h2 className="text-center text-xl font-semibold text-gray-900 mb-2">
                    {t("Dashboard.TotalUsers")}
                  </h2>
                  <p className="text-center mt-4 text-4xl font-bold text-blue-600">
                    {userCount}
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
