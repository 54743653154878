import React from "react";
import { FaCopy, FaTags } from "react-icons/fa";
import { RecordCount } from "../components/RecordCount";
import { useTranslation } from "react-i18next";

const DictionaryTable = ({ records, filteredRecords, copyToClipboard }) => {
    const { t } = useTranslation();
    const [showTags, setShowTags] = React.useState(false);

    return (
        <div className="w-full overflow-x-auto mb-20">
            <table className="w-full border-collapse">
                <thead>
                <tr>
                    <th className="left-0 z-10 border border-gray-300 px-4 py-2">
                        {t("German")} (<RecordCount status="Accepted" />)
                    </th>
                    <th className="border border-gray-300 px-4 py-2">
                        {t("Malagasy")}
                    </th>
                </tr>
                </thead>
                <tbody>
                {filteredRecords.map((record) => (
                    <tr key={record.id}>
                        <td className="border border-gray-300 px-4 py-2">
                            <div className="flex justify-between items-center">
                                {record.article
                                    ? `${record.german} (${record.article})`
                                    : record.german}
                                <span
                                    onClick={() => copyToClipboard(record.german)}
                                    className="cursor-pointer text-gray-500 hover:text-gray-600 transition duration-150 ease-in-out"
                                >
                    <FaCopy />
                  </span>
                            </div>
                        </td>
                        <td className="border border-gray-300 px-4 py-2">
                            <div className="flex justify-between items-center">
                  <span className="flex items-center">
                    {record.phoneticSpelling
                        ? `${record.translation} [${record.phoneticSpelling}]`
                        : record.translation}
                      {record.tags.length > 0 && (
                          <span className="ml-1">
                        <FaTags
                            onClick={() => setShowTags(!showTags)}
                            className="cursor-pointer text-gray-500 hover:text-gray-600 transition duration-150 ease-in-out"
                        />
                              {showTags && (
                                  <div className="ml-1">
                                      {record.tags.map((tag, index) => (
                                          <span
                                              key={index}
                                              className="inline-block bg-blue-100 px-2 rounded-full text-sm font-medium mr-1"
                                          >
                                {t(`Tags.${tag}`)}
                              </span>
                                      ))}
                                  </div>
                              )}
                      </span>
                      )}
                  </span>
                                <span
                                    onClick={() => copyToClipboard(record.translation)}
                                    className="cursor-pointer text-gray-500 hover:text-gray-600 transition duration-150 ease-in-out"
                                >
                    <FaCopy />
                  </span>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default DictionaryTable;
