import React, { useState } from "react";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  serverTimestamp,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaInfoCircle } from "react-icons/fa";

export default function RequestTranslation() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState("german"); // Default to German
  const [requestedWord, setRequestedWord] = useState("");
  const { t } = useTranslation();
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);
  const [existingRecord, setExistingRecord] = useState(null);

  function onChangeLanguage(e) {
    setLanguage(e.target.value);
  }

  function onChangeRequestedWord(e) {
    setRequestedWord(e.target.value);
  }

  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);

    // Check for duplicates in the German word field
    const duplicateField = await checkForDuplicates(
      "germanWord",
      requestedWord
    );
    if (duplicateField) {
      handleDuplicate(duplicateField);
      return;
    }

    // Check for duplicates in the translation field
    const duplicateTranslation = await checkForDuplicates(
      "translation",
      requestedWord
    );
    if (duplicateTranslation) {
      handleDuplicate(duplicateTranslation);
      return;
    }

    // If no duplicates are found, create a new translation request
    await createTranslationRequest();
  }

  // Submit the request even if there are duplicates
  async function onSubmitDuplicate(e) {
    e.preventDefault();
    setLoading(true);
    await createTranslationRequest();
  }

  // Function to check for duplicate entries in the database
  async function checkForDuplicates(field, value) {
    const recordsRef = collection(db, "records");
    const querySnapshot = await getDocs(
      query(recordsRef, where(field, "==", value))
    );

    return querySnapshot.empty ? null : { field, value };
  }

  // Handle the case when a duplicate is found
  function handleDuplicate(duplicate) {
    handleDuplicateDialogOpen({
      field: duplicate.field,
      value: duplicate.value,
    });
    setLoading(false);
  }

  const handleDuplicateDialogOpen = (record) => {
    setExistingRecord(record);
    setIsDuplicateDialogOpen(true);
  };

  const handleDuplicateDialogClose = () => {
    setIsDuplicateDialogOpen(false);
    setExistingRecord(null);
  };

  // Function to create a new translation request in the database
  async function createTranslationRequest() {
    const submittedBy = auth.currentUser ? auth.currentUser.uid : "anonymous";

    const requestData = {
      dateSubmitted: serverTimestamp(),
      submittedBy: submittedBy,
      userRef: submittedBy,
      status: "Requested",
      germanWord: language === "german" ? requestedWord : null,
      translation: language === "malagasy" ? requestedWord : null,
    };

    try {
      await addDoc(collection(db, "records"), requestData);
      setLoading(false);
      toast.success(t("Toast.RequestTranslation.Success"));
      navigate("/dictionary");
    } catch (error) {
      setLoading(false);
      toast.error(t("Toast.RequestTranslation.Error"));
    }
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <main className="max-w-md px-2 mx-auto">
      <h1 className="text-3xl text-center mt-6 font-bold">
        {t("RequestTranslation.Title")}
      </h1>
      <form onSubmit={onSubmit}>
        <p className="text-lg mt-6 font-semibold">
          {t("RequestTranslation.SelectLanguage")}
        </p>
        <select
          id="language"
          value={language}
          onChange={onChangeLanguage}
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded mb-6"
        >
          <option value="german">{t("German")}</option>
          <option value="malagasy">{t("Malagasy")}</option>
        </select>

        <p className="text-lg mt-6 font-semibold">
          {t("RequestTranslation.RequestedWord")}
        </p>
        <input
          type="text"
          id="requestedWord"
          value={requestedWord}
          onChange={onChangeRequestedWord}
          placeholder={t("RequestTranslation.Placeholder.RequestedWord")}
          required
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded mb-6"
        />
        <div className="mb-6 text-center text-gray-600">
          <FaInfoCircle className="inline mr-2 text-xl text-blue-600" />
          {t("CreateRecord.SpaceHint")}
        </div>
        <button
          type="submit"
          className="mb-6 w-full px-7 py-3 bg-blue-600 text-white font-medium text-sm uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
        >
          {t("RequestTranslation.Button")}
        </button>
      </form>

      {/* Duplicate Record Dialog */}
      {isDuplicateDialogOpen && existingRecord && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md max-w-md w-full">
            <div className="flex items-center">
              <FaInfoCircle className="mr-2 text-2xl text-yellow-500" />
              <h3 className="font-bold text-lg">
                {t("DuplicateValueWarningModal.Title")}
              </h3>
            </div>
            <p className="mt-2">{t("DuplicateValueWarningModal.Subtitle")}</p>
            <p className="mt-2">
              {t("DuplicateValueWarningModal.ConfirmationPrompt", {
                field:
                  existingRecord.field === "germanWord"
                    ? t("German")
                    : t("Malagasy"),
                value: existingRecord.value,
              })}
            </p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={handleDuplicateDialogClose}
                className="px-4 py-2 bg-gray-300 rounded-md text-gray-700 mr-2 hover:bg-gray-400"
              >
                {t("DuplicateValueWarningModal.Cancel")}
              </button>
              <button
                onClick={onSubmitDuplicate}
                className="px-4 py-2 bg-blue-600 rounded-md text-white hover:bg-blue-700"
              >
                {t("DuplicateValueWarningModal.CreateDuplicate")}
              </button>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
