import React from "react";
import { useTranslation } from "react-i18next";
import { FaLinkedin, FaXing, FaGithub } from "react-icons/fa";

export default function Imprint() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">{t("Imprint")}</h1>
      <div className="mb-4">
        <a
          className="font-semibold text-blue-600 hover:text-blue-700"
          href="https://madagassisch.de"
        >
          www.madagassisch.de
        </a>
      </div>
      <div className="mb-4">
        <p className="font-semibold">{t("Imprint.Operator")}</p>
        {/* <p>Your Company Name</p>  */}
        <p>Nico Wickersheim</p>
      </div>
      <div className="mb-4">
        <p className="font-semibold">{t("Contact")}:</p>
        <p>E-Mail: nico.wickersheim@yahoo.de</p>
        <p>
          Website:{" "}
          <a
            className="font-semibold text-blue-600 hover:text-blue-700"
            href="https://nicowickersheim.dev"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.nicowickersheim.dev
          </a>
        </p>
        <div className="flex items-center">
          {" "}
          {/* Flex container for text and icon */}
          <p className="mr-2">LinkedIn:</p>
          <a
            className="font-semibold text-blue-600 hover:text-blue-700"
            href="https://www.linkedin.com/in/nico-wickersheim/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin size={20} /> {/* LinkedIn icon */}
          </a>
        </div>
        <div className="flex items-center">
          {" "}
          {/* Flex container for text and icon */}
          <p className="mr-2">Xing:</p>
          <a
            className="font-semibold text-green-600 hover:text-green-700"
            href="https://www.xing.com/profile/Nico_Wickersheim"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaXing size={20} /> {/* LinkedIn icon */}
          </a>
        </div>
        <div className="flex items-center">
          {" "}
          {/* Flex container for text and icon */}
          <p className="mr-2">GitHub:</p>
          <a
            className="font-semibold text-purple-600 hover:text-purple-700"
            href="https://github.com/wickenico"
            target="_blank"
            rel="Github Profile nofollow noopener noreferrer"
          >
            <FaGithub size={20} /> {/* LinkedIn icon */}
          </a>
        </div>
        <div className="flex items-center">
          {" "}
          {/* Flex container for text and icon */}
          <p className="mr-2">{t("Imprint.Donate")}</p>
          <a href="https://ko-fi.com/N4N6EEQB" target="_blank" rel="noreferrer">
            <img
              height="26"
              style={{ border: "0px", height: "26px" }}
              src="https://storage.ko-fi.com/cdn/kofi1.png?v=3"
              border="0"
              alt="Buy Me a Coffee at ko-fi.com"
            />
          </a>
        </div>
      </div>
      <div className="mb-4">
        <p className="font-semibold">{t("Imprint.Responsible")}</p>
        <p>Nico Wickersheim</p>
      </div>
      <div className="mb-4">
        <p className="font-semibold">{t("Imprint.Liability")}</p>
        <p>{t("Imprint.Liability.Text")}</p>
      </div>
      <div className="mb-4">
        <p className="font-semibold">{t("Imprint.Liability.Links")}</p>
        <p>{t("Imprint.Liability.Links.Text")}</p>
      </div>
      <div className="mb-4">
        <p className="font-semibold">{t("Copyright")}</p>
        <p>{t("Copyright.Text")}</p>
      </div>
      <div className="mb-4">
        <p className="font-semibold">{t("Privacy")}</p>
        <p>{t("Privacy.Text")}</p>
      </div>
      <div className="mb-4">
        <p className="font-semibold">{t("Disclaimer")}</p>
        <p>{t("Disclaimer.Text")}</p>
      </div>
    </div>
  );
}
