import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import enTranslation from "./translations/en.json";
import frTranslation from "./translations/fr.json";
import deTranslation from "./translations/de.json";
import mgTranslation from "./translations/mg.json";

const LANG_STORAGE_KEY = "preferredLanguage";

// Get the preferred language from storage or use the browser language
const preferredLanguage =
  localStorage.getItem(LANG_STORAGE_KEY) || navigator.language;

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    fr: { translation: frTranslation },
    de: { translation: deTranslation },
    mg: { translation: mgTranslation },
  },
  lng: navigator.language,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

i18n.on("languageChanged", (lang) => {
  localStorage.setItem(LANG_STORAGE_KEY, lang);
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
