import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

const DictionaryPDF = ({ records }) => {
  const { t } = useTranslation();

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    header: {
      fontSize: 18,
      marginBottom: 10,
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: { margin: "auto", flexDirection: "row" },
    tableColHeader: {
      width: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: 5,
    },
    tableCol: {
      width: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderBottomWidth: 0,
      padding: 5,
      fontSize: 12,
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.header}>{t("Dictionary")}</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>{t("German")}</Text>
              <Text style={styles.tableColHeader}>{t("Malagasy")}</Text>
            </View>
            {records.map((record) => (
              <View key={record.id} style={styles.tableRow}>
                <Text style={styles.tableCol}>{record.german}</Text>
                <Text style={styles.tableCol}>{record.translation}</Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DictionaryPDF;
