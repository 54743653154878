export const TAGS = {
  FOOD: "Food",
  TRAVEL: "Travel",
  EDUCATION: "Education",
  TECHNOLOGY: "Technology",
  CULTURE: "Culture",
  NUMBER: "Number",
  ANIMALS: "Animals",
  COLORS: "Colors",
  FAMILY: "Family",
  NATURE: "Nature",
  BODY: "Body",
  ACTIVITY: "Activity",
  CLOTHING: "Clothing",
  HOUSE: "House",
  TIME: "Time",
  CALENDAR: "Calendar",
};

export const getAllTags = () => Object.values(TAGS);
