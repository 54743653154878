import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LogoutButton() {
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const { t } = useTranslation();

  const handleLogout = () => {
    setIsLogoutDialogOpen(true);
  };

  const confirmLogout = () => {
    signOut(auth).then(() => {
      setIsLogoutDialogOpen(false);
      toast.warning(t("Logout.Success"));
      setTimeout(() => {
        navigate("/");
      }, 3000); // Redirect after 3 seconds
    });
  };

  const cancelLogout = () => {
    setIsLogoutDialogOpen(false);
  };

  return (
    <>
      <button
        onClick={handleLogout}
        className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md"
      >
        {t("Logout")}
      </button>

      {isLogoutDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-md max-w-sm w-full">
            <h2 className="text-lg text-black font-semibold mb-4">
              {t("Logout")}
            </h2>
            <p className="mb-6 text-black">{t("confirmLogout")}</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={cancelLogout}
                className="bg-gray-200 hover:bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
              >
                {t("Cancel")}
              </button>
              <button
                onClick={confirmLogout}
                className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md"
              >
                {t("Logout")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LogoutButton;
