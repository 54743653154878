import React from "react";
import { useTranslation } from "react-i18next";
import meImg from "../assets/me.jpg";

export default function AboutUs() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8 text-center">
        {t("AboutUs.Title")}
      </h1>
      <div className="flex flex-col md:flex-row items-center">
        {/* Text Section */}
        <div className="md:w-1/2 mb-8 md:mb-0 md:mr-8">
          <div className="mb-4">
            <p className="text-lg">{t("AboutUs.Intro")}</p>
          </div>
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-2">
              {t("AboutUs.OurMission")}
            </h2>
            <p>{t("AboutUs.MissionText")}</p>
          </div>
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-2">
              {t("AboutUs.OurVision")}
            </h2>
            <p>{t("AboutUs.VisionText")}</p>
          </div>
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-2">
              {t("AboutUs.Values")}
            </h2>
            <p>{t("AboutUs.ValuesText")}</p>
          </div>
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-2">
              {t("AboutUs.OurStory")}
            </h2>
            <p>{t("AboutUs.StoryText")}</p>
          </div>
          <div className="mb-4">
            <h2 className="text-2xl font-semibold mb-2">{t("AboutUs.Me")}</h2>
            <p>{t("AboutUs.MeText")}</p>
          </div>
        </div>
        {/* Image Section */}
        <div className="md:w-1/2">
          <img
            src={meImg}
            alt="Nico Me"
            className="rounded-lg shadow-lg w-full h-auto"
          />
        </div>
      </div>
    </div>
  );
}
