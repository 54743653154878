import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function InfoBanner() {
  const [isVisible, setIsVisible] = useState(true); // State to control visibility
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Set a timer to hide the banner after 5 seconds
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 5000);

    // Cleanup function to clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []); // Empty dependency array to run the effect only once on mount

  // Return null if the banner is not visible
  if (!isVisible) {
    return null;
  }

  // Render the banner if it is visible
  return (
    // Error banner with red background
    // <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
    //   <p>Unexpected errors have occurred. We working on fixing them.</p>
    // </div>

    // Success banner with green background
    <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4">
      <p>
        {t("InfoBanner.InstallHomeScreen")}&nbsp;
        <Link to="/faq" className="text-blue-700 underline hover:text-blue-800">
          FAQ
        </Link>
      </p>
    </div>
  );
}
