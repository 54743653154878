import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  collection,
  getDocs,
  getDoc,
  orderBy,
  query,
  where,
  doc,
  setDoc,
  serverTimestamp,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import Spinner from "../components/Spinner";
import {
  FaCopy,
  FaTimes,
  FaSearch,
  FaTrash,
  FaPenNib,
  FaCheck,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"; // Import useNavigate

export default function AdminDictionaryReview() {
  const [records, setRecords] = useState([]); // Ensure setRecords is correctly defined
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchRecords = async () => {
    try {
      const recordRef = collection(db, "records");
      const q = query(
        recordRef,
        where("status", "in", ["Requested", "Pending"]),
        orderBy("germanWord", "asc")
      );
      const querySnap = await getDocs(q);
      const recordsData = [];
      querySnap.forEach((doc) => {
        const data = doc.data();
        recordsData.push({
          id: doc.id,
          german: data.germanWord,
          translation: data.translation,
          phoneticSpelling: data.phoneticSpelling,
          status: data.status,
          dateReviewed: data.dateReviewed,
          article: data.article,
        });
      });
      setRecords(recordsData);
      setLoading(false);
      setFilteredRecords(recordsData);
    } catch (error) {
      toast.error(t("Toast.FetchListing.Error"));
    }
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    toast.success(t("Toast.CopyCell.Success"));
  };

  const acceptRecord = async (recordId) => {
    try {
      const recordRef = doc(db, "records", recordId);
      const recordDoc = await getDoc(recordRef);
      const { translation, germanWord } = recordDoc.data();

      if (!translation) {
        toast.error(t("Toast.Review.Accept.EmptyTranslation"));
        return;
      } else if (!germanWord) {
        toast.error(t("Toast.Review.Accept.EmptyGerman"));
        return;
      }

      await setDoc(
        recordRef,
        { status: "Accepted", dateReviewed: serverTimestamp() },
        { merge: true }
      );
      toast.success(t("Toast.Review.Accept.Success"));
      fetchRecords();
    } catch (error) {
      console.error("Error accepting record: ", error);
    }
  };

  const deleteRecord = async (recordId) => {
    try {
      const isConfirmed = window.confirm(t("ConfirmDeleteMessage"));
      if (isConfirmed) {
        const recordRef = doc(db, "records", recordId);
        await deleteDoc(recordRef);
        toast.success(t("Toast.DeleteRecord.Success"));
        fetchRecords();
      }
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);

    const filtered = records.filter((record) => {
      const searchTermLower = e.target.value.toLowerCase();
      const germanMatch =
        record.german && record.german.toLowerCase().includes(searchTermLower);
      const translationMatch =
        record.translation &&
        record.translation.toLowerCase().includes(searchTermLower);
      return germanMatch || translationMatch;
    });

    setFilteredRecords(filtered);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setFilteredRecords(records);
  };

  const openRecordEditPage = (record) => {
    setSelectedRecord(record);
    navigate("/edit-record", { state: { selectedRecord: record } }); // Pass selectedRecord in state
  };

  return (
    <div className="max-w-6xl mx-auto px-3 flex flex-col items-center justify-center">
      <h1 className="text-3xl text-center mt-6 font-bold mb-6">
        Admin - {t("Dictionary")} - {t("Review")}
      </h1>
      <div className="mb-4 relative">
        <div className="flex items-center">
          <span className="absolute left-4 text-gray-500">
            <FaSearch
              className={`transition-colors ${
                searchTerm ? "text-blue-600" : ""
              }`}
            />
          </span>
          <input
            type="text"
            placeholder={t("Dictionary.SearchPlaceholder")}
            value={searchTerm}
            onChange={handleSearchInputChange}
            className="w-full px-10 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600"
          />
          {searchTerm && (
            <span
              onClick={handleClearSearch}
              className="absolute right-4 cursor-pointer text-red-500 hover:text-red-600 transition duration-150 ease-in-out"
            >
              <FaTimes />
            </span>
          )}
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : filteredRecords && filteredRecords.length > 0 ? (
        <div className="w-full overflow-x-auto mb-20">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="left-0 z-10 border border-gray-300 px-4 py-2">
                  {t("German")}
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  {t("Malagasy")}
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  {t("Status")}
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  {t("Actions")}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredRecords.map((record) => (
                <tr key={record.id}>
                  <td className="border border-gray-300 px-4 py-2">
                    <div className="flex justify-between items-center">
                      {record.article
                        ? `${record.german} (${record.article})`
                        : record.german}
                      {record.german && (
                        <span
                          onClick={() => copyToClipboard(record.german)}
                          className="cursor-pointer text-gray-500 hover:text-gray-600 transition duration-150 ease-in-out"
                        >
                          <FaCopy />
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <div className="flex justify-between items-center">
                      <span className="flex items-center">
                        {record.phoneticSpelling
                          ? `${record.translation} [${record.phoneticSpelling}]`
                          : record.translation}
                      </span>
                      <span
                        onClick={() => copyToClipboard(record.translation)}
                        className="cursor-pointer text-gray-500 hover:text-gray-600 transition duration-150 ease-in-out"
                      >
                        <FaCopy />
                      </span>
                    </div>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <div className="flex justify-between items-center">
                      {record.status === "Accepted" ? (
                        <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
                          <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                          {t("Status.Accepted")}
                        </span>
                      ) : record.status === "Pending" ? (
                        <span className="inline-flex items-center gap-1 rounded-full bg-orange-50 px-2 py-1 text-xs font-semibold text-orange-600">
                          <span className="h-1.5 w-1.5 rounded-full bg-orange-600"></span>
                          {t("Status.Pending")}
                        </span>
                      ) : record.status === "Requested" ? (
                        <span className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600">
                          <span className="h-1.5 w-1.5 rounded-full bg-blue-600"></span>
                          {t("Status.Requested")}
                        </span>
                      ) : (
                        record.status
                      )}
                    </div>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <div className="flex justify-left items-center">
                      <span
                        onClick={() => openRecordEditPage(record)}
                        className="cursor-pointer text-blue-500 hover:text-blue-600 transition duration-150 ease-in-out mr-2"
                      >
                        <FaPenNib />
                      </span>
                      <span
                        onClick={() => acceptRecord(record.id)}
                        className="cursor-pointer text-green-500 hover:text-green-600 transition duration-150 ease-in-out mr-2"
                      >
                        <FaCheck />
                      </span>
                      <span
                        onClick={() => deleteRecord(record.id)}
                        className="cursor-pointer text-red-500 hover:text-red-600 transition duration-150 ease-in-out mr-2"
                      >
                        <FaTrash />
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>{t("NoRecordsFound")}</p>
      )}
    </div>
  );
}
