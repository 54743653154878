import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AiFillTags } from "react-icons/ai";
import { db } from "../firebase";
import { doc, updateDoc, collection } from "firebase/firestore";
import { TAGS } from "../tags";
import { useNavigate, useLocation } from "react-router-dom";

export default function AssignTagsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTags, setSelectedTags] = useState([]);
  const record = location.state?.record || {}; // Get the record from navigation state
  const assignedTags = record.tags || [];

  useEffect(() => {
    setSelectedTags(assignedTags); // Set initial selected tags based on the record
  }, [assignedTags]);

  const handleCheckboxChange = (tag) => {
    setSelectedTags((prevSelectedTags) => {
      if (prevSelectedTags.includes(tag)) {
        return prevSelectedTags.filter((selectedTag) => selectedTag !== tag);
      } else {
        return [...prevSelectedTags, tag];
      }
    });
  };

  const handleAssignTags = async () => {
    const recordRef = doc(collection(db, "records"), record.id);

    try {
      // Update the record in Firestore
      await updateDoc(recordRef, { tags: selectedTags });

      toast.success(t("Toast.TagsAssigned"));
      navigate(-1); // Navigate back to the previous page
    } catch (error) {
      console.error("Error updating record:", error);
      toast.error(t("Toast.TagsAssignmentError"));
    }
  };

  const handleCancel = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="max-w-2xl mx-auto px-4 py-6">
      <div className="flex items-center mb-4">
        <AiFillTags className="h-6 w-6 text-blue-600 mr-2" />
        <h1 className="text-2xl font-semibold text-gray-900">
          {t("AssignTagsModal.Title")}
        </h1>
      </div>
      <p className="text-sm text-gray-500 mb-6">
        {t("AssignTagsModal.Subtitle")}
      </p>

      {/* Display Record Details */}
      <div className="mb-6 p-4 bg-gray-100 rounded shadow">
        <p className="text-sm text-gray-700">
          <strong>{t("German")}: </strong>
          {record.article
            ? `${record.article} ${record.german}`
            : record.german}
        </p>
        <p className="text-sm text-gray-700">
          <strong>{t("Malagasy")}: </strong>
          {record.phoneticSpelling
            ? `${record.translation} [${record.phoneticSpelling}]`
            : record.translation}
        </p>
      </div>

      <div className="grid grid-cols-2 gap-4">
        {Object.values(TAGS).map((tag) => (
          <div key={tag} className="mb-2">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={selectedTags.includes(tag)}
                onChange={() => handleCheckboxChange(tag)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="ml-2">{t(`Tags.${tag}`)}</span>
            </label>
          </div>
        ))}
      </div>
      <div className="mt-6 flex justify-end">
        <button
          type="button"
          className="px-4 py-2 bg-blue-600 text-white font-medium rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={handleAssignTags}
        >
          {t("AssignTagsModal.Assign")}
        </button>
        <button
          type="button"
          className="ml-3 px-4 py-2 bg-gray-300 text-gray-700 font-medium rounded hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
          onClick={handleCancel}
        >
          {t("AssignTagsModal.Cancel")}
        </button>
      </div>
    </div>
  );
}
