import React from "react";
import error from "../assets/svg/error.svg";
import { useTranslation } from "react-i18next";

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
      <img src={error} alt="Loading..." className="h-44" />
      <div className="text-2xl text-white font-semibold mb-4">
        {t("NotFound.PageNotFound")}
      </div>
      <p className="text-lg text-white text-center max-w-md">
        {t("NotFound.Text")}
        <a href="/" className="underline hover:text-yellow-300">
          {t("Home")}
        </a>
        .
      </p>
    </div>
  );
}
