import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import PrivateRoute from "./components/PrivateRoute";
import ForgotPassword from "./pages/ForgotPassword";
import Dictionary from "./pages/Dictionary";
import Header from "./components/Header";
import CreateRecord from "./pages/CreateRecord";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDictionary from "./pages/AdminDictionary";
import AdminDictionaryReview from "./pages/AdminDictionaryReview";
import AdminCreateRecord from "./pages/AdminCreateRecord";
import NotFound from "./components/NotFound";
import Imprint from "./pages/Imprint";
import Dashboard from "./pages/Dashboard";
import Contact from "./pages/Contact";
import { useTranslation, I18nextProvider } from "react-i18next";
import UserList from "./pages/UserList";
import Footer from "./components/Footer";
import RequestTranslation from "./pages/RequestTranslation";
import FAQ from "./pages/FAQ";
import Blog from "./pages/Blog";
import BlogPost from "./components/BlogPost";
import RecordEditPage from "./pages/RecordEditPage";
import AssignTagsPage from "./pages/AssignTagsPage";
import NewUserPage from "./pages/NewUserPage";
import ScrollToTopButton from "./components/ScrollToTopButton";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import InfoBanner from "./components/InfoBanner";

function App() {
  const { i18n } = useTranslation();

  return (
    <div className="min-h-screen flex flex-col">
      <I18nextProvider i18n={i18n}>
        <>
          <Router>
            <Header />
            <InfoBanner />
            <div className="flex-grow">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/users" element={<PrivateRoute />}>
                  <Route path="/users" element={<UserList />} />
                </Route>
                <Route path="/admin-dashboard" element={<PrivateRoute />}>
                  <Route path="/admin-dashboard" element={<Dashboard />} />
                </Route>
                <Route path="/request" element={<RequestTranslation />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/Dictionary" element={<Dictionary />} />
                <Route path="/Imprint" element={<Imprint />} />
                <Route path="about-us" element={<AboutUs />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:id" element={<BlogPost />} />
                <Route path="/edit-record" element={<RecordEditPage />} />
                <Route path="/assign-tags" element={<AssignTagsPage />} />
                <Route path="/new-user" element={<PrivateRoute />}>
                  <Route path="/new-user" element={<NewUserPage />} />
                </Route>
                <Route path="/Admin-Dictionary" element={<PrivateRoute />}>
                  <Route
                    path="/Admin-Dictionary"
                    element={<AdminDictionary />}
                  />
                </Route>
                <Route
                  path="/Admin-Dictionary-Review"
                  element={<PrivateRoute />}
                >
                  <Route
                    path="/Admin-Dictionary-Review"
                    element={<AdminDictionaryReview />}
                  />
                </Route>
                <Route path="/create-record" element={<CreateRecord />} />
                <Route path="/admin-create-record" element={<PrivateRoute />}>
                  <Route
                    path="/admin-create-record"
                    element={<AdminCreateRecord />}
                  />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
            <Footer />
          </Router>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </>
        <ScrollToTopButton />
      </I18nextProvider>
    </div>
  );
}

export default App;
