import React, { useState } from "react";
import { toast } from "react-toastify";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const { name, email, message } = formData;
  const { t } = useTranslation();

  const subjectOptions = [
    t("Contact.Subject.General"),
    t("Contact.Subject.Support"),
    t("Contact.Subject.Bug"),
    t("Contact.Subject.Request"),
    // Add more options as needed
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prevent multiple submissions while processing
    if (loading) {
      return;
    }

    try {
      setLoading(true);
      const templateParams = {
        from_name: formData.name,
        from_email: formData.email,
        subject: formData.subject,
        message: formData.message,
      };

      // Use the service ID and template ID from EmailJS
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      // Add the form data to the Firestore collection
      const docRef = await addDoc(collection(db, "requests"), formData);

      toast.success(t("Toast.ContactForm.Success"));
      setFormData({
        name: "",
        email: "",
        message: "",
        subject: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.success(t("Toast.ContactForm.Error"));
    } finally {
      setLoading(false); // Set loading to false after form submission
    }
  };

  return (
    <div className="max-w-6xl mx-auto px-3 flex flex-col items-center justify-center">
      <h1 className="text-3xl text-center mt-6 font-bold mb-6">
        {t("Contact.Title")}
      </h1>
      <p
        className="text-center mb-6"
        dangerouslySetInnerHTML={{ __html: t("Contact.Description") }}
      ></p>
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-lg bg-white shadow-md rounded-lg p-6"
      >
        <div className="mb-4">
          <label
            htmlFor="name"
            className="text-gray-600 font-medium block mb-2"
          >
            {t("Name")} {"(*)"}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded focus:border-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="text-gray-600 font-medium block mb-2"
          >
            {t("Email")} {"(*)"}
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded focus:border-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="subject"
            className="text-gray-600 font-medium block mb-2"
          >
            {t("Subject")} {"(*)"}
          </label>
          <select
            id="subject"
            name="subject"
            value={formData.subject || ""}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded focus:border-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
          >
            <option value="" disabled>
              {t("Contact.Subject.Select")}
            </option>
            {subjectOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="message"
            className="text-gray-600 font-medium block mb-2"
          >
            {t("Message")} {"(*)"}
          </label>
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={handleChange}
            required
            rows="4"
            className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded focus:border-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white uppercase px-6 py-2 text-sm font-medium rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg"
        >
          {loading ? t("Submitting") : t("Submit")}
        </button>
      </form>
    </div>
  );
}
