import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";

export function RecordCount({ status }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const recordsCollection = collection(db, "records"); // Replace "records" with your actual collection name
        const q = query(recordsCollection, where("status", "==", status));
        const querySnapshot = await getDocs(q);
        setCount(querySnapshot.size);
      } catch (error) {
        console.error(`Error fetching count for status ${status}:`, error);
        setCount(0);
      }
    };

    fetchCount();
  }, [status]);

  return count;
}
